@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-hp-who-we-work-with .paragraphs-items-field-sub-paragraphs {
  @include clearfix;
//  @include grid-row();
//  padding: 0 15px 80px;
  //perspective: 800px;
  //
  //.field-name-field-section {
  //  perspective: 800px;
  //}
  .field-name-field-section {
    overflow: hidden;
  }

  a {
    display: block;
  }
}


.card-holder {
  width: 100%;
  height: 100%;
  position: relative;
  //position: absolute;
  //transform-style: preserve-3d;
  //transition: transform 1s;
  //transform-origin: right center;
}
.paragraphs-item-bit-client-logo-flip-panels {
  width: 214px;
  height: 224px;
  padding-bottom: 10px;

  @include breakpoint($desktop) {
    width: 234px;
    height: 234px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  float: left;
  //height: 330px;
  position: relative;
  //perspective: 800px;
  //background: #e6e6e6;

  .card-holder {
    //backface-visibility: hidden;
  }

  //&:hover .card-holder {
    //transform: translateX( -100% ) rotateY( -180deg ) translateZ(1px);
  //}


  //.field-name-field-image {
  //  height: 110px;
  //  img {
  //    max-height: 100px;
  //  }
  //}

  div.field-name-field-title {
    color: $color-gold;
    text-transform: uppercase;
    font-size: 25px;
    &:after {
      content: "";
      margin: 0 auto;
      display: block;
      width: 20px;
      border-bottom: 1px solid $color-gold;
      padding-top: 16px;
    }
  }

  .vcenter {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .ds-front, .ds-back {
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    backface-visibility: hidden;
    background: white;
    text-align: center;

  }
  .ds-front {
    z-index: 2;
  }
  .ds-back {
    background: #09a7e0;
    padding: 0 20px;
    text-align: left;
    p {
      font-size: 15px;
      line-height: 1.3em;
      padding-bottom: 0;
      margin-bottom: 0.5em;
    }
    p:last-child {
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 2px solid white;
      font-size: 14px;

      &:after {
        position: absolute;
        display: inline-block;
        content: "\e80c";
        right: -15px;
        font-family: "fontello";
        transform: rotate(90deg);
        font-weight: normal;
      }
    }
  }
  .ds-back a {
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  //.ds-back {
  //  transform: rotateY( 180deg )  translateZ(1px);
  //  background: #4ccb8b;
  //}
  p {
    color: white;
  }
}

